
import { defineComponent, onMounted, ref} from "vue";
import RedPrize from "./RedPrize.vue";
import Modal from "@/components/UI/Modal";
import { useRouter } from "vue-router";
import $ from "jquery";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from "qs";
export default defineComponent({
  name: "Mid",
  components: {
    RedPrize
  },
  setup() {

    ($.fn as any).WheelSurf = function (options: any) {
        const _default = {
            outerCircle: {
                color: '#df1e15'
            },
            innerCircle: {
                color: '#f4ad26'
            },
            dots: ['#fbf0a9', '#fbb936'],
            disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'],
            title: {
                color: '#5c1e08',
                font: '19px Arial'
            }
        }

        $.extend(_default,options)
        // 画布中心移动到canvas中心

        /* eslint-disable */
        const _this = this[0],
            width = _this.width,
            height = _this.height,
            ctx = _this.getContext("2d"),
            imgs: any = [],
            awardTitle: any = [],
            awardPic: any = []
        for (const item in (_default as any).list) {
            awardTitle.push((_default as any).list[item].name)
            imgs.push((_default as any).list[item].image)
        }
        const num = imgs.length
        // 圆心
        const x = width / 2
        const y = height / 2
        ctx.translate(x, y)

        return {
            init: function (angelTo: any) {
                angelTo = angelTo || 0;

                ctx.clearRect(-this.width, -this.height, this.width, this.height);

                // 平分角度
                const angel = (2 * Math.PI / 360) * (360 / num);
                let startAngel = 2 * Math.PI / 360 * (-90)
                let endAngel = 2 * Math.PI / 360 * (-90) + angel

                // 旋转画布
                ctx.save()
                ctx.rotate(angelTo * Math.PI / 180);
                // 画外圆
                ctx.beginPath();
                ctx.lineWidth = 25;
                ctx.strokeStyle = _default.outerCircle.color;
                ctx.arc(0, 0, 243, 0, 2 * Math.PI)
                ctx.stroke();
                // 画里圆
                ctx.beginPath();
                ctx.lineWidth = 23;
                ctx.strokeStyle = _default.innerCircle.color;
                ctx.arc(0, 0, 218, 0, 2 * Math.PI)
                ctx.stroke();

                // 装饰点
                const dotColor = _default.dots
                for (let i = 0; i < 12; i++) {
                    // 装饰点 圆心 坐标计算
                    ctx.beginPath();
                    const radius = 230;
                    const xr = radius * Math.cos(startAngel)
                    const yr = radius * Math.sin(startAngel)

                    ctx.fillStyle = dotColor[i % dotColor.length]
                    ctx.arc(xr, yr, 11, 0, 2 * Math.PI)
                    ctx.fill()

                    startAngel += (2 * Math.PI / 360) * (360 / 12);

                }
                // 画里转盘                
                const colors = _default.disk
                for (let i = 0; i < num; i++) {
                    ctx.beginPath();
                    ctx.lineWidth = 208;
                    ctx.strokeStyle = colors[i % colors.length]
                    ctx.arc(0, 0, 104, startAngel, endAngel)
                    ctx.stroke();
                    startAngel = endAngel
                    endAngel += angel
                }
                // 添加奖品
                function loadImg() {

                    const dtd = $.Deferred()
                    let countImg = 0
                    if (awardPic.length) {
                        return dtd.resolve(awardPic);
                    }
                    for (let i = 0; i < num; i++) {
                        const img = new Image()
                        awardPic.push(img)

                        img.src = imgs[i]
                        img.onload = function () {
                            countImg++
                            if (countImg == num) {
                                dtd.resolve(awardPic);
                            }
                        }
                    }
                    return dtd.promise()
                }

                $.when(loadImg()).done(function (awardPic) {

                    startAngel = angel / 2
                    for (let i = 0; i < num; i++) {
                        ctx.save();
                        ctx.rotate(startAngel)
                        ctx.drawImage(awardPic[i], -36, -23 - 130, 70, 70);
                        ctx.font = _default.title.font;
                        ctx.fillStyle = _default.title.color
                        ctx.textAlign = "center";
                        ctx.fillText(awardTitle[i], 0, -170);
                        startAngel += angel
                        ctx.restore();
                    }
                })
                ctx.restore();
            },
            /**
             * @param angel 旋转角度
             * @param callback 转完后的回调函数
             */
            lottery: function (angel: any, callback: any) {
                angel = angel || 0
                angel = 360-angel
                angel += 720
                // 基值（减速）
                const baseStep = 30
                // 起始滚动速度
                const baseSpeed = 0.3
                // 步长
                let count = 1;
                const _this = this
                const timer = setInterval(function () {

                    _this.init(count)
                    if (count == angel) {
                        clearInterval(timer)
                        if (typeof callback == "function") {
                            callback()
                        }
                    }

                    count = count + baseStep * (((angel - count) / angel) > baseSpeed ? baseSpeed : ((angel - count) / angel))
                    if (angel - count < 0.5) {
                        count = angel
                    }

                }, 25)
            }
        }

    }

    const showRedPrize = ref(false)
    const awardName = ref()
    const awardImg = ref()
    const router = useRouter();
    //初始化查看奖品链接
    let rewardType=3;

    var wheelSurf: any
    // 初始化装盘数据 正常情况下应该由后台返回
    var initData = {
            "success": true,
            "list": [
                {
                    "id": 1,
                    "name": "包邮券",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/%E5%8C%85%E9%82%AE%E5%88%B8.png",
                    "rank": 1,
                    "percent": 10
                },
                {
                    "id": 2,
                    "name": "50元优惠券",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/50%E5%85%83%E4%BC%98%E6%83%A0%E5%88%B8.png",
                    "rank": 7,
                    "percent": 20
                },
                {
                    "id": 3,
                    "name": "100元优惠券",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/100%E5%85%83%E4%BC%98%E6%83%A0%E5%88%B8.png" ,
                    "rank": 3,
                    "percent": 10
                },
                {
                    "id": 4,
                    "name": "200元优惠券",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/200%E5%85%83%E4%BC%98%E6%83%A0%E5%88%B8.png",
                    "rank": 5,
                    "percent": 15
                },
                {
                    "id": 5,
                    "name": "保温杯",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/smsbwb.png",
                    "rank": 6,
                    "percent": 15
                },
                {
                    "id": 6,
                    "name": "2024积分",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/news/efbdb01a177c02bacce78dfc521205e9.png",
                    "rank": 2,
                    "percent": 10
                },
                {
                    "id": 1221111,
                    "name": "小米手机",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/xmsj.png",
                    "rank": 8,
                    "percent": 10
                },
                {
                    "id": 1212222,
                    "name": "电动牙刷",
                    "image": "https://yingzegbw.oss-cn-beijing.aliyuncs.com/Event/ddys.png",
                    "rank": 4,
                    "percent": 10
                }
            ]
        }


    var list = {}
    var angel = 360 / initData.list.length
    // 格式化成插件需要的奖品列表格式
    for (var i = 0, l = initData.list.length; i < l; i++) {
        list[initData.list[i].rank] = {
            id: initData.list[i].id,
            name: initData.list[i].name,
            image: initData.list[i].image
        }
    }
    var throttle = true;


    onMounted(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            router.push({
                name: "Login",
                query: {
                    redirect: router.currentRoute.value.fullPath,
                },
            });
            return false;
        }

        // 定义转盘奖品
        wheelSurf = ($('#myCanvas') as any).WheelSurf({
            list: list, // 奖品 列表，(必填)
            outerCircle: {
                color: '#e74b44' // 外圈颜色(可选)
            },
            innerCircle: {
                color: '#f4ad26' // 里圈颜色(可选)
            },
            //dots: ['#fbf0a9', '#fbb936'], // 装饰点颜色(可选)
            dots: ['#fff7af', '#fafffe'],
            //disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'], //中心奖盘的颜色，默认7彩(可选)
            disk: ['#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0'],
            title: {
                color: '#5c1e08',
                font: '16px Arial'
            } // 奖品标题样式(可选)
        })
        // 初始化转盘
        wheelSurf.init()

        $("#start").on('click', function () {
            const token = localStorage.getItem("token");
            if (!token) {
              Modal({
                title: "温馨提示",
                content: "登录后才可以进行抽奖哦~",
                onClose: () => {
                  router.push({
                    name: "Login",
                    query: {
                      redirect: router.currentRoute.value.fullPath,
                    },
                  });
                },
              });
              return false;
            }
            var winData: any; // 正常情况下获奖信息应该由后台返回
            axios
              .post("/M/Event/doCommonActivityNew", 
                qs.stringify({
                  eventUniqueMark: 'WorkStart_2024',
                  tag: 1,
                })
              )
              .then((res: any) => {
                // console.log(data);
                if (res.data.success) {
                    winData = res.data.data
                    rewardType=res.data.data.rewardType
                    throttle = false;
                    var count = 0
                    // 计算奖品角度
                    for (var key in list) {
                        if (list.hasOwnProperty(key)) {
                            if (winData.rewardNum == list[key].id) {
                                winData.image = list[key].image
                                break;
                            }
                            count++
                        }
                    }

                    // 转盘抽奖，
                    wheelSurf.lottery((count * angel + angel / 2), function () {
                        awardName.value = winData.rewardName
                        awardImg.value = winData.image
                        showRedPrize.value = true
                        throttle = true;
                    })

                } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 2000,
                    });
                }
              })
              .catch((err) => {
                Toast({
                      title: '执行错误，请联系管理员！',
                      type: "error",
                    });
              });
        });
    })

    function onClosePrize() {
        showRedPrize.value = false;
        if(rewardType===1){
            router.push({
                name: "Intergral"
            });
            
        }else if(rewardType===2){
            router.push({
                name: "Coupon"
            });
        }else {
            router.push({
                name: "Prize"
            });
        }



      }

    return {
        showRedPrize,
        onClosePrize,
        awardImg,
        awardName
    }
  },
});
